import React from "react";

import styled from "styled-components/macro";

import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  Paper as MuiPaper,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { BpProjects } from "../../services";

import ProjectsForm from "../../components/ProjectsForm";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function Private() {
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    (async () => {
      setData({});
    })();
  }, []);

  const handleSubmit = async (form) => {
    await BpProjects.create({
      ...form,
    });

    alert("Empreendimento cadastrado com sucesso");
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Preencha os dados abaixo para criar um novo empreendimento
        </Typography>

        <ProjectsForm
          data={data}
          handleSubmit={handleSubmit}
          buttonName="Cadastrar"
        />
      </CardContent>
    </Card>
  );
}

export const ProjectsFormPage = () => {
  return (
    <Box p={8}>
      <Helmet title="Settings" />

      <Typography variant="h3" gutterBottom display="inline">
        Novo empreendimento
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Inicio
        </Link>
        <Link component={NavLink} exact to="/bp/projects">
          Empreendimentos
        </Link>
        <Typography>Cadastro de empreendimentos</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Private />
        </Grid>
      </Grid>
    </Box>
  );
};
